import React from 'react'
import './styles.scss'

const Preview = ({refTable, name, position, phoneNumber, email, website, leftText, rightText, fontFamily}) => {
  return (
    <div className="preview-window" ref={refTable}>
        <html><head>
            <meta  http-equiv="content-type"  content="text/html; charset=utf-8" />
            </head><body>
            <table id="generator-table" cellpadding="0" cellspacing="0" style={{ fontFamily: fontFamily, fontSize: '16px', fontWeight: '700' }} width="550">
                <tbody>
                    <tr>
                        <td style={{ display: "block", paddingRight: "18px", verticalAlign: "top" }}>
                            <table width="198" cellpadding="0" cellspacing="0" style={ { fontFamily: fontFamily, fontSize: '16px' } }>
                                <tbody>
                                    <tr>
                                        <td>
                                            <img src="https://generator-stopki.s1.norddigital.pl/logo.png" alt="koolthings.com" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td>
                            <table width="334" cellpadding="0" cellspacing="0" style={ { fontFamily: fontFamily, fontSize: '16px' } }>
                                <tbody>
                                    <tr>
                                        <td style={{ fontFamily: fontFamily, color: name.color, fontWeight: name.weight }}>{name.value}</td>
                                    </tr>
                                    <tr style={{ display: "block", marginBottom: "10px" }}>
                                        <td style={{ fontFamily: fontFamily, color: position.color, fontWeight: position.weight }}>{position.value}</td>
                                    </tr>
                                    <tr>
                                        <td><a style={{textDecoration: "none !important", textDecoration: "none", fontFamily: fontFamily, color: phoneNumber.color, fontWeight: phoneNumber.weight }} href={`tel:${phoneNumber.value}`}><font color={phoneNumber.color}>{phoneNumber.value}</font></a></td>
                                    </tr>
                                    <tr>
                                        <td><a style={{textDecoration: "none !important", textDecoration: "none", fontFamily: fontFamily, color: email.color, fontWeight: email.weight }} href={`mailto:${email.value}`}><font color={email.color}>{email.value}</font></a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr style={{ display: "block", paddingBottom: "10px" }}></tr>
                    <tr>
                        <td>
                            <table cellpadding="0" cellspacing="0" style={ { fontFamily: fontFamily, fontSize: '16px' } }>
                                <tbody>
                                    <tr>
                                        <td><a style={{textDecoration: "none !important", textDecoration: "none", fontFamily: fontFamily, color: website.color, fontWeight: website.weight }} target="__blank" href={website.value}><font color={website.color}>{website.value}</font></a></td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: "12px", fontFamily: fontFamily, color: leftText.color, fontWeight: leftText.weight }}>{leftText.value}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td style={{ verticalAlign: "bottom" }}>
                            <table cellpadding="0" cellspacing="0" style={ { fontFamily: fontFamily, fontSize: '16px' } }>
                                <tbody>
                                    <tr>
                                        <td style={{ fontSize: "12px", fontFamily: fontFamily, color: rightText.color, fontWeight: rightText.weight }}>{rightText.value}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </body></html>
    </div>
  );
}

export default Preview;
