import React, { useState, useRef, useEffect } from 'react'
import Input from './components/Input/Input';
import Preview from './components/preview/Preview';
import { SketchPicker } from 'react-color';

const App = () => {
  let [ load, setLoad ] = useState(false)
  let [ name, setName ] = useState('')
  let [ position, setPosition ] = useState('');
  let [ phoneNumber, setPhoneNumber ] = useState('');
  let [ email, setEmail ] = useState('');
  let [ website, setWebsite ] = useState('');
  let [ leftText, setLeftText ] = useState('');
  let [ rightText, setRightText ] = useState('');
  const refTable = useRef(null);
  let [ fontColor, setFontColor ] = useState('#000000');
  let [ buttonCopyHtml, setButtonCopyHtml ] = useState('Skopiuj HTML');
  let [ fontFamily, setFontFamily ] = useState('Arial')

  const copyCode = () => {
    let table = refTable.current.innerHTML
    // table = `<html><head><meta  http-equiv="content-type"  content="text/html; charset=utf-8" /></head><body>${table}</body></html>`
    navigator.clipboard.writeText(table)
    setButtonCopyHtml('Skopiowano !');
    setTimeout(function(){ setButtonCopyHtml('Skopiuj ponownie') }, 5000);
  }

  // useEffect(() => {
  //   if(!load) {
  //     setName({
  //       value: "Dawid Dobrzyński",
  //       color: '#000000',
  //       weight: '500'
  //     });
  //     setPosition({
  //       value: "Web Developer",
  //       color: '#000000',
  //       weight: '700'
  //     });
  //     setPhoneNumber({
  //       value: "112 112 112",
  //       color: '#000000',
  //       weight: '500'
  //     })
  //     setEmail({
  //       value: "dawid.dobrzynski@norddigital.com",
  //       color: '#F50C0C',
  //       weight: '500'
  //     })
  //     setWebsite({
  //       value: "https://norddigital.com",
  //       color: '#41F111',
  //       weight: '700'
  //     })
  //     setLeftText({
  //       value: "Tekst po lewej",
  //       color: '#000000',
  //       weight: '500'
  //     })
  //     setRightText({
  //       value: "Tekst po prawej",
  //       color: '#000000',
  //       weight: '500'
  //     })
  //   }
  // }, [load])

  return (
    <>
      <div className="app" id="app">
        <div className="container">
          <div className="wrapper-form">
            <div className="row-items">
              <div className="menu">
                <div className="row">
                  <Input 
                    label="Imię i nazwisko"
                    setFunction={(value) => setName(value)}
                  />
                </div>
                <div className="row">
                  <Input 
                    label="Stanowisko"
                    setFunction={(value) => setPosition(value)}
                  />
                </div>
                <div className="row">
                  <Input 
                    label="Numer telefonu"
                    setFunction={(value) => setPhoneNumber(value)}
                  />
                </div>
                <div className="row">
                  <Input 
                    label="Email"
                    setFunction={(value) => setEmail(value)}
                  />
                </div>
                <div className="row">
                  <Input 
                    label="Strona internetowa"
                    setFunction={(value) => setWebsite(value)}
                  />
                </div>
                <div className="row">
                  <Input 
                    label="Informacja na dole z lewej"
                    setFunction={(value) => setLeftText(value)}
                  />
                </div>
                <div className="row">
                  <Input 
                    label="Informacja na dole z prawej"
                    setFunction={(value) => setRightText(value)}
                  />
                </div>
                <div className="row select">
                  <select onChange={(e) => setFontFamily(e.target.value)}>
                    <option value="Arial">Arial</option>
                    <option value="Verdana">Verdana</option>
                    <option value="Helvetica">Helvetica</option>
                    <option value="Tahoma">Tahoma</option>
                    <option value="Roboto">Roboto</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Times New Roman">Times New Roman</option>
                    <option value="sans-serif">Sans serif</option>
                    <option value="Jost">Jost</option>
                  </select>  
                </div>
                <div className="row colorPicker">
                  <SketchPicker
                    width="100%"
                    color={ fontColor }
                    onChangeComplete={ (color) => setFontColor(color.hex) }
                  />
                </div>
              </div>
              <div className="preview">
                <Preview refTable={refTable} fontFamily={fontFamily} name={name} position={position} phoneNumber={phoneNumber} email={email} website={website} leftText={leftText} rightText={rightText} />
              </div>
            </div>
            <div className="actions">
              {typeof window !== "undefined" &&
                <input className="btn" type="button" value={buttonCopyHtml} onClick={(e) => copyCode(e)} />
              }
            </div>
          </div>
        </div>
      </div>
      <div className="app-mobile">
        <h1>Strona działa tylko na większych urządzeniach</h1>
      </div>
    </>
  );
}

export default App;