import React, { useState, useEffect } from 'react'
import './styles.scss'

const Input = (props) => {
  let [ value, setValue ] = useState('');
  let [ color, setColor ] = useState('000000');
  let [ bold, setBold ] = useState(false);

  useEffect(() => {
      props.setFunction({
        value: value,
        color: '#' + color,
        weight: bold ? '700' : '500'
      });
  }, [value, color, bold])

  return (
    <div className="wrapper">
      <div className={value.length > 0 ? 'input-wrapper with-value' : 'input-wrapper'}>
          <input type="text" value={value} onChange={(e) => setValue(e.target.value)} />
          {props.label &&
            <label>{props.label}</label>
          }
      </div>
      <div className="options-box">
          <label class="container-checkbox">Bold
            <input type="checkbox" checked={bold} />
            <span class="checkmark" onClick={() => setBold(!bold)}></span>
          </label>
          <input className="color" type="text" value={color} onChange={(e) => setColor(e.target.value)} />
      </div>
    </div>
  );
}

export default Input;
